import EditStaffCodeModal from 'components/EditStaffCodeModal';
import ExchangeSerialNumberModal from 'components/ExchangeSerialNumberModal';
import ExchangeWarrantyProductModal from 'components/ExchangeWarrantyProductModal';
import { Form, Formik, useFormik } from 'formik';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import { FaEdit } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Select from 'react-select';
import { Button, Card, Input } from 'reactstrap';
import CommonUtil from 'utils/CommonUtil';

import Layout from '../components/Layout';
import MaintenanceProcessModal from '../components/maintainanceContent/MaintenanceProcessModal';
import EditMaintainanceDetailModal from '../components/maintainanceContent/EditMaintainanceDetailModal';
import ModificationHandleModal from '../components/maintainanceContent/ModificationHandleModal';
import {
  addRepairRequest,
  getMaintainDetails,
  getMaintainStatusOptions,
  getRelatedRegisterList,
  getRepairBrandOption,
  getRepairHandlerOptions,
  getRepairOptions,
  updateRepairDealing,
} from '../redux/actions/data/repairActions';
import {
  MAINTAIN_ADD_RESET,
  MAINTAIN_DEALING_UPDATE_RESET,
} from '../redux/constants/data/repairConstants';
import style from '../styles/layout.module.css';
import MessageUtil from '../utils/MessageUtil';
import history from '../utils/createHistory';
import useScrapWarehouseOptions from '../utils/useScrapWarehouseOptions';

const MaintainanceContent = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [modal, setModal] = useState(false);
  const [info, setInfo] = useState(null);
  const [sender_city, setSender_city] = useState('');

  const [receiver_city, setReceiver_city] = useState('');

  const [historyModal, setHistoryModal] = useState(false);

  const [allShow, setAllShow] = useState(false);
  const [isCredit, setIsCredit] = useState(false);

  const { maintainDetailsInfo, error } = useSelector(
    state => state.maintainDetails,
  );

  const { maintainOptions } = useSelector(state => state.maintainOptions);
  const { maintainHandlerOptions } = useSelector(
    state => state.maintainHandlerOptions,
  );
  const { maintainStatusOptions, loading: maintainStatusOptionsLoading } =
    useSelector(state => state.maintainStatusOptions);
  const {
    loading: updateLoading,
    success: updateSuccess,
    error: updateError,
  } = useSelector(state => state.maintainDetailUpdate);
  const {
    loading: updateDealingLoading,
    success: updateDealingSuccess,
    error: updateDealingError,
  } = useSelector(state => state.maintainDealingUpdate);

  const [isCopy, setIsCopy] = useState(false);
  const { options: scrapWarehouseOptions } = useScrapWarehouseOptions();

  const {
    success: addSuccess,
    error: addError,
    id: responseId,
  } = useSelector(state => state.maintainAdd);

  const { values, setFieldValue, handleSubmit, resetForm, setValues } =
    useFormik({
      enableReinitialize: true,
      initialValues: {
        backtoCompanyDate: info?.review.backtoCompanyDate,
        deliveryCharge: info?.review.deliveryCharge,
        handler: maintainHandlerOptions?.find(
          user => user.value == info?.review.handler,
        ),
        handlingDetail: info?.review.handlingDetail,
        id: info?.id,
        invoiceDate: info?.review.invoiceDate ?? '',
        invoicePrice: info?.review.invoicePrice,
        isCredit: info?.review.isCredit,
        logisticsNum: info?.review.logisticsNum,
        newWarrantyMonth: info?.review.newWarrantyMonth,
        newWarrantyYear: info?.review.newWarrantyYear,
        repairDate: info?.review.repairDate ?? '',
        repairFinishedDate: info?.review.repairFinishedDate ?? '',
        repairItemInfo: info?.review.repairItemInfo,
        retailingPrice: info?.review.retailingPrice,
        scrapWareHouseCode: info?.review?.scrapWarehouseCode ?? '',
        sendBackDate: info?.review.sendBackDate ?? '',
        sendWay: info?.review.sendWay,
        status: { label: info?.review.status, value: info?.review.statusCode },
        stock: info?.review.stock,
      },
      onSubmit: () => {
        const body = {
          ...values,
          backtoCompanyDate:
            values.backtoCompanyDate === '' ? null : values.backtoCompanyDate,
          handler: values.handler?.value || 0,
          invoiceDate: values.invoiceDate === '' ? null : values.invoiceDate,
          repairDate: values.repairDate === '' ? null : values.repairDate,
          repairFinishedDate:
            values.repairFinishedDate === '' ? null : values.repairFinishedDate,
          sendBackDate: values.sendBackDate === '' ? null : values.sendBackDate,
          status: values.status.label,
          statusCode: values.status.value,
        };

        const _values = maintainDetailsInfo;
        const copyData = _values
          ? {
              brandId: _values.brandId,
              color: _values.color,
              id: _values.id,
              isParallelGoods: _values.isParallelGoods,
              model: _values.model,
              modelId: _values.modelId,
              otherRepiarItem: _values.otherRepiarItem,
              productProblem: _values.productProblem,
              productSerailNum: _values.productSerailNum,
              purchaseDate: _values.purchaseDate,
              receiver: {
                address_receiver: _values.receiver.address_receiver,
                cityId_receiver: _values.receiver.cityId_receiver,
                countryCode_receiver: '886',
                countryId_receiver: _values.receiver.countryId_receiver,
                email_receiver: _values.receiver.email_receiver,
                firstName_receiver: _values.receiver.firstName_receiver,
                lastName_receiver: _values.receiver.lastName_receiver,
                phone_receiver: _values.receiver.phone_receiver,
                townId_receiver: _values.receiver.townId_receiver,
              },
              repairItems: [..._values.repairItems],
              sender: {
                address_sender: _values.sender.address_sender,
                cityId_sender: _values.sender.cityId_sender,
                countryCode_sender: '886',
                countryId_sender: _values.sender.countryId_sender,
                email_sender: _values.sender.email_sender,
                firstName_sender: _values.sender.firstName_sender,
                lastName_sender: _values.sender.lastName_sender,
                phone_sender: _values.sender.phone_sender,
                townId_sender: _values.sender.townId_sender,
              },
              supplier: {
                csType: _values.supplier.csType,
                supplierRepairNum: isCopy
                  ? ''
                  : _values.supplier.supplierRepairNum,
                supplier_receiver: _values.supplier.supplier_receiver,
                supplier_receiverType: _values.supplier.supplier_receiverType,

                // supplierQuote: 0,
                supplier_sender: _values.supplier.supplier_sender,
              },
              supplierChannel: _values.supplierChannel,
              supplierChannelId: _values.supplierChannelId,
              warrantySerailNum: _values.warrantySerailNum,
            }
          : {};

        if (_values) {
          if (
            _values.supplier.csType === '庫存報廢' ||
            _values.supplier.supplier_receiverType === '經銷商'
          ) {
            delete body.receiver;
            delete body.sender;
          }

          if (_values.supplier.supplier_receiverType === '客戶') {
            delete body.sender;
          }

          if (_values.supplier.csType !== '庫存報廢') {
            delete body.isParallelGoods;
          }

          if (!isCopy) {
            // console.log(body);
            dispatch(updateRepairDealing(body));
          } else {
            dispatch(addRepairRequest(maintainDetailsInfo.source, copyData));
            setCopyVaules(body);
          }
        }

        resetForm();
      },
    });

  // useEffect(() => {
  //   if (error && error.status === 401) {
  //     dispatch(userLogout());
  //     history.push({ pathname: '/Login', state: '/MaintainanceContent' });
  //     MessageUtil.alertWanring('請重新登入取得授權');
  //   } else if (userInfo) {
  //     //比對token時間
  //     if (moment(Date.now()).isAfter(new Date(userInfo.authValidTime))) {
  //       dispatch(userLogout());
  //       history.push({ pathname: '/Login', state: '/MaintainanceContent' });
  //       MessageUtil.alertWanring('請重新登入取得授權');
  //     }
  //   }

  // }, [userInfo, error]);

  useEffect(() => {
    if (error) {
      MessageUtil.alertWanring(error);
    }
  }, [error]);

  useEffect(() => {
    if (updateDealingSuccess) {
      MessageUtil.toastSuccess('更新成功!!');
      dispatch(getMaintainDetails(id));
      dispatch({ type: MAINTAIN_DEALING_UPDATE_RESET });
    }

    if (updateDealingError) {
      MessageUtil.alertWanring(
        '更新失敗',
        updateDealingError.title || updateDealingError.message,
      );
      dispatch({ type: MAINTAIN_DEALING_UPDATE_RESET });
    }

    // return () => {
    //   dispatch({ type: MAINTAIN_DEALING_UPDATE_RESET });
    // };
  }, [updateDealingSuccess, updateDealingError]);

  useEffect(() => {
    dispatch(getMaintainDetails(id));
  }, []);

  useEffect(() => {
    if (maintainDetailsInfo) {
      //因為只有區域會因為城市不同而摳到不同的選項
      setSender_city(maintainDetailsInfo.sender.cityId_sender);
      setReceiver_city(maintainDetailsInfo.receiver.cityId_receiver);

      //credit改由useState控制
      setIsCredit(maintainDetailsInfo.review.isCredit);
    }
    setInfo(maintainDetailsInfo);
  }, [maintainDetailsInfo]);

  useEffect(() => {
    if (addSuccess) {
      MessageUtil.alertWanring('新增成功!');
      dispatch({ type: MAINTAIN_ADD_RESET });
      setIsCopy(false);
      localStorage.removeItem('isCopy');
      dispatch(
        updateRepairDealing({
          ...copyValues,
          id: responseId,
        }),
      );
      dispatch(getMaintainDetails(responseId));
      history.push(`/MaintainanceContent/${responseId}`);
    }
    if (addError) {
      MessageUtil.alertWanring('新增失敗!', addError);
      dispatch({ type: MAINTAIN_ADD_RESET });
    }
  }, [addSuccess, addError]);

  useEffect(() => {
    dispatch(getRepairOptions());
    dispatch(getMaintainStatusOptions());
    dispatch(getRepairHandlerOptions());
    dispatch(getRepairBrandOption(0, 0));

    // dispatch(getRepairModelOption(0, 0, ''));
    // dispatch(getColorModelOption(0, 0, ''));

    if (localStorage.getItem('isCopy')) {
      setIsCopy(true);
    }
  }, []);

  const { relatedList } = useSelector(state => state.getRelatedRegisterList);

  useEffect(() => {
    dispatch(getRelatedRegisterList(maintainDetailsInfo?.warrantySerailNum));
  }, [maintainDetailsInfo?.warrantySerailNum]);

  const toggleModal = () => setModal(!modal);
  const toggleAllShow = () => setAllShow(!allShow);
  const toggleHistoryModal = () => {
    setHistoryModal(false);
  };

  const [copyValues, setCopyVaules] = useState(null);

  // 保固註冊 - 折舊換新 modal
  const [
    isExchangeWarrantyProductModalOpen,
    setIsExchangeWarrantyProductModalOpen,
  ] = useState(false);
  const toggleExchangeWarrantyProductModal = () => {
    setIsExchangeWarrantyProductModalOpen(!isExchangeWarrantyProductModalOpen);
  };

  // 更換產品序號 modal
  const [isExchangeSerialNumberModalOpen, setIsExchangeSerialNumberModalOpen] =
    useState(false);
  const toggleExchangeSerialNumberModal = () => {
    setIsExchangeSerialNumberModalOpen(!isExchangeSerialNumberModalOpen);
  };

  // 更換服務人員代碼
  const [isEditStaffCodeModalOpen, setIsEditStaffCodeModalOpen] =
    useState(false);
  const toggleEditStaffCodeModal = () => {
    setIsEditStaffCodeModalOpen(!isEditStaffCodeModalOpen);
  };

  useEffect(() => {
    if (maintainDetailsInfo) {
      if (JSON.stringify(maintainDetailsInfo) !== '{}') {
        CommonUtil.consoleLog({ anno: 'response', data: maintainDetailsInfo });
      }
    }
  }, [maintainDetailsInfo]);

  return (
    <Layout
      pageTitle="維修單內容"
      items={[
        { isActive: false, page_name: '維修清單', to: '/MaintainanceList' },
        { isActive: true, page_name: '維修單內容' },
      ]}
    >
      {info && (
        <div
          className={style.show_flex}
          style={{
            alignItems: 'flex-start',
            flexWrap: 'nowrap',
            marginBottom: '2rem',
          }}
        >
          {relatedList?.length > 1 && !isCopy && (
            <React.Fragment>
              <Card
                className={`${style.search_card} ${style['repair-record-card']}`}
              >
                <div className={style.card_header}>
                  <span>維修紀錄</span>
                </div>
                {relatedList.map((list, index) => {
                  return (
                    <div
                      key={index}
                      style={{
                        marginBottom: '0.5rem',
                        marginLeft: '1.5rem',
                        marginRight: '1.5rem',
                        marginTop: '0.5rem',
                      }}
                    >
                      <div
                        onClick={() => {
                          window.open(`/#/MaintainanceContent/${list.id}`);
                        }}
                      >
                        <span
                          style={{
                            color: '#0077FF',
                            cursor: 'pointer',
                            fontWeight: 'bold',
                          }}
                        >
                          {list.repairRegisterNum}
                        </span>
                      </div>
                      <div>
                        <small>{list.createTime}</small>
                      </div>
                    </div>
                  );
                })}
              </Card>
              <div style={{ margin: '1rem' }}></div>
            </React.Fragment>
          )}
          <div style={{ width: '100%' }}>
            {info?.isExchangedNew && (
              <div
                className={`${style.card_header} ${style['repair-exchange-product']}`}
              >
                <div>此產品為折價換新來的 ({info?.warrantyEndDate_w})</div>
                <div>保固序號：{info?.warrantySerailNum_w}</div>
                <div>產品序號：{info?.productSerailNum_w}</div>
              </div>
            )}
            {info?.changeRecords?.length > 0 &&
              info.changeRecords.map((record, index) => (
                <div
                  key={index}
                  className={`${style.card_header} ${style['repair-change-records']}`}
                >
                  <div>更換產品序號 ({record.createTime})</div>
                  <div>原序號： {record.productSerailNumOld}</div>
                  <div>替換序號： {record.productSerailNum}</div>
                </div>
              ))}
            <Card className={style.card} style={{ alignItems: '' }}>
              <label className="fw-bold">
                維修單編號：{!isCopy && info.repairRegisterNum}
              </label>
              <label className="fw-bold">
                申請時間：
                {!isCopy &&
                  moment(new Date(info.createTime)).format('yyyy/MM/DD')}
              </label>
              <label className="fw-bold">
                訂單來源：
                {info.source === 1
                  ? '會員'
                  : info.source === 2
                  ? '客服'
                  : info.source === 3
                  ? '門市'
                  : '經銷商'}
              </label>
              <div className="d-flex align-items-center">
                <label className="fw-bold">
                  服務人員代碼：{info.employeeid}
                </label>
                <div style={{ width: '30px' }}></div>
                <FaEdit
                  style={{ color: '#0077ff', cursor: 'pointer' }}
                  onClick={toggleEditStaffCodeModal}
                />
              </div>
              {info.store.stType === '一般' && (
                <label className="fw-bold">
                  服務人員門市：{info.employeeStore}
                </label>
              )}
              {info.source === 3 || info.source === 1 ? (
                <label className="fw-bold">
                  會員電話：{info.phone ? `0${info.phone}` : ''}
                </label>
              ) : (
                <></>
              )}
            </Card>
          </div>
        </div>
      )}

      {info && (
        <Card className={style.search_card} style={{ marginBottom: '3rem' }}>
          <div className={style.card_header}>
            維修單資訊
            <Button
              style={{ backgroundColor: '#3c44b1', color: 'white' }}
              className="d-inline-flex"
              onClick={() => toggleModal()}
            >
              <FaEdit className="mx-2" size={24} />
              編輯
            </Button>
          </div>
          <div className={style.card_body}>
            {info.supplier.supplierRepairNum && (
              <div className={style.formGroup}>
                <label className="fw-bold w150">經銷商維修單號</label>
                <div className={style['form-repair-detail']}>
                  <span>{info.supplier.supplierRepairNum}</span>
                </div>
              </div>
            )}

            {/* 門市資料 */}
            {Number(info.source) === 3 && (
              <>
                {info.store.storeName_sender && (
                  <div
                    className={style.formGroup}
                    style={{ alignItems: 'center', flexWrap: 'wrap' }}
                  >
                    <label className="fw-bold w150">寄件人資訊</label>
                    <div className={style['form-repair-detail']}>
                      <span>{info.store.storeName_sender}</span>
                    </div>
                  </div>
                )}
                {info.store.stType !== '內部庫存報廢' && (
                  <div
                    className={style.formGroup}
                    style={{ alignItems: 'center', flexWrap: 'wrap' }}
                  >
                    <label className="fw-bold w150">
                      {info.store.storeName_sender ? '' : '寄件人資訊'}
                    </label>
                    <div className={style['form-repair-detail']}>
                      <div className="mb-2">
                        姓名字：{info.sender.lastName_sender}{' '}
                        {info.sender.firstName_sender}
                      </div>
                      <div className="mb-2">
                        手機號碼：
                        {info.sender.countryCode_sender === '886' &&
                        info.sender.phone_sender
                          ? `0${info.sender.phone_sender}`
                          : info.sender.phone_sender}
                      </div>
                      <div className="mb-2">
                        電子信箱：{info.sender.email_sender}
                      </div>
                      <div className="mb-2">
                        地址： {info.sender.zipCode_sender}{' '}
                        {info.sender.country_sender}
                        {info.sender.city_sender}
                        {info.sender.town_sender}
                        {info.sender.address_sender}
                      </div>
                    </div>
                  </div>
                )}
              </>
            )}

            {/* 寄件人 */}
            {/* 客服或經銷商資料 */}
            {info.sender.firstName_sender && Number(info.source) !== 3 && (
              <div className={style.formGroup}>
                <label className="fw-bold w150">寄件人資訊</label>
                <div className={style['form-repair-detail']}>
                  <div className="mb-2">
                    姓名字：{info.sender.lastName_sender}{' '}
                    {info.sender.firstName_sender}
                  </div>
                  <div className="mb-2">
                    手機號碼：
                    {info.sender.countryCode_sender === '886' &&
                    info.sender.phone_sender
                      ? `0${info.sender.phone_sender}`
                      : info.sender.phone_sender}
                  </div>
                  <div className="mb-2">
                    電子信箱：{info.sender.email_sender}
                  </div>
                  <div className="mb-2">
                    地址： {info.sender.zipCode_sender}{' '}
                    {info.sender.country_sender}
                    {info.sender.city_sender}
                    {info.sender.town_sender}
                    {info.sender.address_sender}
                  </div>
                </div>
              </div>
            )}
            {/* 客服或經銷商資料 */}
            {info.supplier.supplierName_sender && Number(info.source) !== 3 && (
              <div className={style.formGroup}>
                <label className="fw-bold w150">寄件人資訊</label>
                <div className={style['form-repair-detail']}>
                  <span>{info.supplier.supplierName_sender}</span>
                </div>
              </div>
            )}

            {/* 庫存報廢 */}
            {info.supplier.csType === '庫存報廢' && (
              <div className={style.formGroup}>
                <label className="fw-bold w150">寄件人資訊</label>
                <div className={style['form-repair-detail']}>
                  <span>{'百滋國際股份有限公司'}</span>
                </div>
              </div>
            )}

            {/* 門市資料 */}
            {Number(info.source) === 3 && (
              <>
                {info.store.stType !== '內部庫存報廢' ? (
                  <div
                    className={style.formGroup}
                    style={{ alignItems: 'center', flexWrap: 'wrap' }}
                  >
                    <label className="fw-bold w150">收件人資訊</label>
                    <div className={style['form-repair-detail']}>
                      <span>{info.store.storeName_receiver}</span>
                    </div>
                  </div>
                ) : (
                  <div className={style.formGroup}>
                    <label className="fw-bold w150">收件人資訊</label>
                    <div className={style['form-repair-detail']}>
                      <span>{'百滋國際股份有限公司'}</span>
                    </div>
                  </div>
                )}
                {info.store.stType !== '內部庫存報廢' && (
                  <div
                    className={style.formGroup}
                    style={{ alignItems: 'center', flexWrap: 'wrap' }}
                  >
                    <label className="fw-bold w150">
                      {info.store.storeName_receiver ? '' : '收件人資訊'}
                    </label>
                    <div className={style['form-repair-detail']}>
                      <div className="mb-2">
                        姓名字：{info.receiver.lastName_receiver}{' '}
                        {info.receiver.firstName_receiver}
                      </div>
                      <div className="mb-2">
                        手機號碼：
                        {info.receiver.countryCode_receiver === '886' &&
                        info.receiver.phone_receiver
                          ? `0${info.receiver.phone_receiver}`
                          : info.receiver.phone_receiver}
                      </div>
                      <div className="mb-2">
                        電子信箱：{info.receiver.email_receiver}
                      </div>
                      <div className="mb-2">
                        地址： {info.receiver.zipCode_receiver}{' '}
                        {info.receiver.country_receiver}
                        {info.receiver.city_receiver}
                        {info.receiver.town_receiver}
                        {info.receiver.address_receiver}
                      </div>
                    </div>
                  </div>
                )}
              </>
            )}

            {/* 收件人 */}
            {/* 客服或經銷商資料 */}
            {info.receiver.lastName_receiver && Number(info.source) !== 3 && (
              <div className={style.formGroup}>
                <label className="fw-bold w150">收件人資訊</label>
                <div className={style['form-repair-detail']}>
                  <div className="mb-2">
                    姓名字：{info.receiver.lastName_receiver}{' '}
                    {info.receiver.firstName_receiver}
                  </div>
                  <div className="mb-2">
                    手機號碼：
                    {info.receiver.countryCode_receiver === '886' &&
                    info.receiver.phone_receiver
                      ? `0${info.receiver.phone_receiver}`
                      : info.receiver.phone_receiver}
                  </div>
                  <div className="mb-2">
                    電子信箱：{info.receiver.email_receiver}
                  </div>
                  <div className="mb-2">
                    地址： {info.receiver.zipCode_receiver}{' '}
                    {info.receiver.country_receiver}
                    {info.receiver.city_receiver}
                    {info.receiver.town_receiver}
                    {info.receiver.address_receiver}
                  </div>
                </div>
              </div>
            )}

            {/* 客服或經銷商資料 */}
            {info.supplier.supplierName_receiver &&
              Number(info.source) !== 3 && (
                <div className={style.formGroup}>
                  <label className="fw-bold w150">收件人資訊</label>
                  <div className={style['form-repair-detail']}>
                    <span>{info.supplier.supplierName_receiver}</span>
                  </div>
                </div>
              )}

            {/* 庫存報廢 */}
            {info.supplier.csType === '庫存報廢' && (
              <div className={style.formGroup}>
                <label className="fw-bold w150">收件人資訊</label>
                <div className={style['form-repair-detail']}>
                  <span>{'百滋國際股份有限公司'}</span>
                </div>
              </div>
            )}

            <div className={style.formGroup}>
              <label className="fw-bold w150">維修品資訊</label>
              <div className={style['form-repair-detail']}>
                <div className="mb-2">商品品牌：{info?.brandName || '--'}</div>
                <div className="mb-2">商品型號：{info?.model || '--'}</div>
                <div className="mb-2">商品顏色：{info?.color || '--'}</div>
                <div className="mb-2">商品尺寸：{info?.size || '--'}</div>
                <div className="mb-2">
                  商品序號：{info?.productSerailNum || '--'}{' '}
                </div>
                <div className="mb-2">
                  購買日期：
                  {info?.purchaseDate
                    ? moment(info?.purchaseDate).format('YYYY/MM/DD HH:mm:ss')
                    : '--'}
                </div>
                <div className="mb-2 mb-4 supplierChannel">
                  購買通路：{info?.supplierChannel || info?.supplierChannelId || '--'}
                </div>

                <div className="mb-2">
                  保固序號：{info?.warrantySerailNum || '--'}
                </div>
                <div className="mb-2">
                  購買日期：
                  {info?.purchaseDate_w ? moment(new Date(info.purchaseDate_w)).format('yyyy/MM/DD HH:mm:ss') : '--'}
                </div>
                {info.supplier.csType === '非會員' && (
                  <div className="mb-2">
                    水貨：{info.isParallelGoods ? '是' : '否'}
                  </div>
                )}
                {info.supplierChannel && (
                  <div className="mb-2 purchaseChannelName_w">購買通路：{info?.purchaseChannelName_w || info?.purchaseChannel_w || '--'}</div>
                )}
                <div className="mb-2">
                  保固起始日：
                  {info?.warrantyStartDate
                    ? moment(info?.warrantyStartDate).format(
                        'YYYY/MM/DD HH:mm:ss',
                      )
                    : '--'}
                </div>
                <div className="mb-2">
                  保固結束日：
                  {info?.warrantyEndDate
                    ? moment(info?.warrantyEndDate).format(
                        'YYYY/MM/DD HH:mm:ss',
                      )
                    : '--'}
                </div>
                <div className="mb-2">
                  審核狀態 ：{info?.warrantyRegisterStatus || '--'}
                </div>
              </div>
            </div>
            <div className={style.formGroup}>
              <label className="fw-bold w150">檢附品項</label>
              <div className={style['form-repair-detail']}>
                <span>
                  {[info.repairItems, info.otherRepiarItem]
                    .map(item => item)
                    .join(', ')}
                </span>
              </div>
            </div>
            <div className={style.formGroup}>
              <label className="fw-bold w150">商品問題</label>
              <div className={style['form-repair-detail']}>
                <span>{info.productProblem}</span>
              </div>
            </div>
          </div>
        </Card>
      )}
      <div
        style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'left' }}
      >
        <Card
          className={style.search_card}
          style={{
            flex: '1 1 700px',
            marginBottom: '3rem',
            marginRight: '3rem',
          }}
        >
          <div className={style.card_header}>
            <span style={{ whiteSpace: 'nowrap' }}>維修單處理</span>
            <div
              style={{
                alignItems: 'center',
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: 'right',
                width: '100%',
              }}
            >
              {info?.source !== 2 &&
                info?.source !== 4 &&
                info?.store?.stType !== '內部庫存報廢' && (
                  <Button
                    className={`${style['repair-button-orange']}`}
                    color="warning"
                    onClick={toggleExchangeWarrantyProductModal}
                  >
                    保固註冊
                  </Button>
                )}
              <Button
                className={`${style['repair-button-orange']}`}
                color="warning"
                onClick={toggleExchangeSerialNumberModal}
              >
                更換產品序號
              </Button>
            </div>
          </div>
          <div className={style.card_body}>
            <div
              style={{
                color: '#0077ff',
                cursor: 'pointer',
                display: 'flex',
                justifyContent: 'flex-end',
                width: '100%',
              }}
            >
              {!isCopy && (
                <u onClick={() => setHistoryModal(true)}>維修單歷程</u>
              )}
            </div>
            <div className={style.formGroup}>
              <label className={style.maintain_item_label}>維修單狀態 </label>
              <div className={style.maintain_item_input}>
                <div className={style.formGroup}>
                  <Select
                    isLoading={maintainStatusOptionsLoading}
                    isDisabled={maintainStatusOptionsLoading}
                    name="status"
                    className={style['form-select-repair']}
                    options={maintainStatusOptions}
                    onChange={option => {
                      setFieldValue('status', option);
                    }}
                    value={values.status}
                  />
                </div>
                <div
                  className={
                    values.status.value == 10 || values.status.value == 11
                      ? ''
                      : 'd-none'
                  }
                >
                  <div className={style.formGroup}>
                    <label className="w100">末端報價</label>
                    <div className="d-flex align-items-center">
                      <span className="w50 m-0">NT$</span>
                      <Input
                        name="retailingPrice"
                        className="form-control me-3"
                        onChange={event => {
                          setFieldValue('retailingPrice', event.target.value);
                        }}
                        value={values.retailingPrice ?? ''}
                      />
                    </div>
                  </div>
                  <div className={style.formGroup}>
                    <label className="w100">運費</label>
                    <div className="d-flex align-items-center">
                      <span className="w50 m-0">NT$</span>
                      <Input
                        name="deliveryCharge"
                        className="form-control me-3"
                        onChange={event => {
                          setFieldValue('deliveryCharge', event.target.value);
                        }}
                        value={values.deliveryCharge ?? ''}
                      />
                    </div>
                  </div>
                  <div className={values.status.value == 10 ? '' : 'd-none'}>
                    <div className={style.formGroup}>
                      <label className="w120">換新後保固期：</label>
                      <div
                        className="d-flex align-items-center"
                        style={{ flexWrap: 'wrap' }}
                      >
                        <Input
                          type="select"
                          name="warrantyYear"
                          className="form-control"
                          style={{ flex: '1', margin: 0, maxWidth: '200px' }}
                          value={values.newWarrantyYear ?? ''}
                          onChange={event => {
                            setFieldValue(
                              'newWarrantyYear',
                              event.target.value,
                            );
                          }}
                        >
                          <option value="">請選擇</option>
                          <option value="0">0</option>
                          <option value="1">1</option>
                          <option value="2">2</option>
                        </Input>
                        <span className="ms-1 mb-0 me-3">年</span>
                        <Input
                          type="select"
                          name="warrantyMonth"
                          className="form-control"
                          style={{ flex: '1', margin: 0, maxWidth: '200px' }}
                          value={values.newWarrantyMonth ?? ''}
                          onChange={event => {
                            setFieldValue(
                              'newWarrantyMonth',
                              event.target.value,
                            );
                          }}
                        >
                          <option value="">請選擇</option>
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4">4</option>
                          <option value="5">5</option>
                          <option value="6">6</option>
                          <option value="7">7</option>
                          <option value="8">8</option>
                          <option value="9">9</option>
                          <option value="10">10</option>
                          <option value="11">11</option>
                          <option value="12">12</option>
                        </Input>
                        <span className="ms-1 mb-0">月</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={style.formGroup}>
              <label className={style.maintain_item_label}>檢修項目</label>
              <div className={style.maintain_item_input}>
                <Input
                  name="repairItemInfo"
                  type="textarea"
                  rows="5"
                  className="form-control me-3"
                  value={values.repairItemInfo ?? ''}
                  onChange={event => {
                    setFieldValue('repairItemInfo', event.target.value);
                  }}
                />
              </div>
            </div>
            <div className={style.formGroup}>
              <label className={style.maintain_item_label}>百滋處理詳情</label>
              <div className={style.maintain_item_input}>
                <Input
                  name="handlingDetail"
                  type="textarea"
                  rows="5"
                  className="form-control me-3"
                  value={values.handlingDetail ?? ''}
                  onChange={event => {
                    const htmlTag = new RegExp('&lt;br /&gt', 'g');
                    const value = event.target.value.replace(htmlTag, '\r\n');
                    setFieldValue('handlingDetail', value);
                  }}
                />
              </div>
            </div>
            <div className={style.formGroup}>
              <label className={style.maintain_item_label}>送修日期</label>
              <div className={style.maintain_item_input}>
                <DatePicker
                  className="form-control"
                  dateFormat="yyyy/MM/dd"
                  selected={
                    values.repairDate ? moment(values.repairDate).toDate() : ''
                  }
                  onChange={date => {
                    setFieldValue(
                      'repairDate',
                      date ? moment(date).add(8, 'hours').toDate() : null,
                    );
                  }}
                />
              </div>
            </div>
            <div className={style.formGroup}>
              <label className={style.maintain_item_label}>送修完成日期</label>
              <div className={style.maintain_item_input}>
                <DatePicker
                  className="form-control"
                  dateFormat="yyyy/MM/dd"
                  selected={
                    values.repairFinishedDate
                      ? moment(values.repairFinishedDate).toDate()
                      : ''
                  }
                  onChange={date => {
                    setFieldValue(
                      'repairFinishedDate',
                      date ? moment(date).add(8, 'hours').toDate() : null,
                    );
                  }}
                />
              </div>
            </div>
            {info?.source == 3 && (
              <div className={style.formGroup}>
                <label className={style.maintain_item_label}>回公司日期</label>
                <div className={style.maintain_item_input}>
                  <DatePicker
                    className="form-control"
                    dateFormat="yyyy/MM/dd"
                    selected={
                      values.backtoCompanyDate
                        ? moment(values.backtoCompanyDate).toDate()
                        : ''
                    }
                    onChange={date => {
                      setFieldValue(
                        'backtoCompanyDate',
                        date ? moment(date).add(8, 'hours').toDate() : null,
                      );
                    }}
                  />
                </div>
              </div>
            )}
            <div className={style.formGroup}>
              <label className={style.maintain_item_label}>寄回日期</label>
              <div className={style.maintain_item_input}>
                <DatePicker
                  className="form-control"
                  dateFormat="yyyy/MM/dd"
                  selected={
                    values.sendBackDate
                      ? moment(values.sendBackDate).toDate()
                      : ''
                  }
                  onChange={date => {
                    setFieldValue(
                      'sendBackDate',
                      date ? moment(date).add(8, 'hours').toDate() : null,
                    );
                  }}
                />
              </div>
            </div>
            <div className={style.formGroup}>
              <label className={style.maintain_item_label}>寄送方式 </label>
              <Input
                type="select"
                name="sendWay"
                className={`form-control ${style['form-select']}`}
                style={{ margin: 0 }}
                value={values.sendWay ?? ''}
                onChange={event => {
                  setFieldValue('sendWay', event.target.value);
                }}
              >
                <option value="無">無</option>
                <option value="黑貓宅急便">黑貓宅急便</option>
                <option value="新竹物流">新竹物流</option>
                <option value="中華郵政掛號">中華郵政掛號</option>
                <option value="顧客親取">顧客親取</option>
                <option value="庫存報廢">庫存報廢</option>
              </Input>
            </div>
            <div className={style.formGroup}>
              <label className={style.maintain_item_label}>寄送物流單號 </label>
              <div className={style.maintain_item_input}>
                <Input
                  name="logisticsNum"
                  className="form-control me-3"
                  value={values.logisticsNum ?? ''}
                  onChange={event => {
                    setFieldValue('logisticsNum', event.target.value);
                  }}
                />
              </div>
            </div>
            <div className={style.formGroup}>
              <label className={style.maintain_item_label}>經手人</label>
              <Select
                name="handler"
                isLoading={maintainStatusOptionsLoading}
                isDisabled={maintainStatusOptionsLoading}
                className={style['form-select']}
                options={maintainHandlerOptions}
                onChange={option => {
                  setFieldValue('handler', option);
                }}
                value={values.handler}
              />
            </div>
            <div className={style.formGroup}>
              <label className={style.maintain_item_label}>庫存</label>
              <Input
                type="select"
                name="stock"
                className={`${style['form-select']}`}
                style={{ margin: 0 }}
                value={values.stock ?? ''}
                onChange={event => {
                  setFieldValue('stock', event.target.value);
                }}
              >
                <option value="">請選擇庫存狀態</option>
                <option value="no">no</option>
                <option value="ok">ok</option>
              </Input>
            </div>
            <div className={style.formGroup}>
              <label className={style.maintain_item_label}>credit</label>
              <input
                type="checkbox"
                id="credit"
                className={style.checkbox_input}
                onChange={event => {
                  setFieldValue('isCredit', event.target.checked);
                }}
                checked={values.isCredit}
              />
              <label htmlFor="credit">credit</label>
            </div>
            <div className={style.formGroup}>
              <label className={style.maintain_item_label}>報廢倉庫</label>
              <Select
                name="scrapWareHouse"
                className={style['form-select']}
                options={
                  scrapWarehouseOptions && [
                    { label: '無', value: '' },
                    ...scrapWarehouseOptions,
                  ]
                }
                onChange={option => {
                  setFieldValue('scrapWareHouseCode', option.value);
                }}
                value={
                  scrapWarehouseOptions.filter(
                    o => o.value == values.scrapWareHouseCode,
                  )[0] || { label: '無', value: '' }
                }
                style={{ margin: 0 }}
              />
            </div>
            <div className={style.formGroup}>
              <label className={style.maintain_item_label}>修繕請款日期</label>
              <div className={style.maintain_item_input}>
                <DatePicker
                  className="form-control"
                  dateFormat="yyyy/MM/dd"
                  selected={
                    values.invoiceDate
                      ? moment(values.invoiceDate).toDate()
                      : ''
                  }
                  onChange={date => {
                    setFieldValue(
                      'invoiceDate',
                      date ? moment(date).add(8, 'hours').toDate() : null,
                    );
                  }}
                />
              </div>
            </div>
            <div className={style.formGroup}>
              <label className={style.maintain_item_label}>修繕請款金額</label>
              <div style={{ marginRight: '0.5rem' }}>NT$</div>
              <Input
                name="invoicePrice"
                className={style['form-input']}
                value={values.invoicePrice ?? ''}
                onChange={event => {
                  setFieldValue('invoicePrice', event.target.value);
                }}
              />
            </div>
            <small>
              異動時間{'　'}
              {info &&
                moment(info.updateTime_admin).format(
                  'yyyy/MM/DD HH:mm:ss',
                )}{' '}
              {info && info.repairProgress[0].admin}
            </small>
            <hr />
            <div className="d-flex justify-content-center mt-5">
              <Button
                className={`me-3 ${style.modal_button_cancel}`}
                onClick={() => {
                  history.push('/MaintainanceList');
                }}
              >
                取消
              </Button>
              <Button
                type="submit"
                className={style.modal_button_submit}
                onClick={handleSubmit}
                disabled={updateLoading}
              >
                {updateLoading && (
                  <span
                    className="me-2 btn-wrapper--icon spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                )}
                <span className="btn-wrapper--label">儲存</span>
              </Button>
            </div>
          </div>
        </Card>
        <div style={{ maxWidth: '340px' }}>
          {info && info.source == 4 && (
            <Card
              className={style.search_card}
              style={{ marginBottom: '3rem' }}
            >
              <div className={style.card_header}>經銷商報價</div>
              <div className="mx-5 my-4">
                <span>
                  NT$
                  {info && info.supplier.supplierQuote}
                </span>
              </div>
            </Card>
          )}
          {!isCopy && (
            <Card
              className={style.search_card}
              style={{ marginBottom: '3rem' }}
            >
              <div className={style.card_header}>報價</div>
              <Formik enableReinitialize initialValues={{}}>
                {props => (
                  <Form className="form-list form-label-140">
                    <div className={style.card_body}>
                      <div>
                        <div className="d-flex justify-content-between">
                          <span>末端報價</span>
                          <span>
                            NT$
                            {info && info.review.retailingPrice}
                          </span>
                        </div>
                        <div className="d-flex justify-content-between">
                          <span>運費</span>
                          <span>NT${info && info.review.deliveryCharge}</span>
                        </div>

                        <div className="d-flex justify-content-between">
                          <span>總額</span>
                          <span>
                            NT$
                            {(info && info.review.retailingPrice) +
                              (info && info.review.deliveryCharge)}
                          </span>
                        </div>
                      </div>
                      <div className={style.payment_info_block}>
                        <h6>收到款項</h6>
                        <span>
                          {info &&
                            info.repairProgress.find(
                              data => data.statusCode == 12,
                            )?.date}
                        </span>
                        <span>
                          帳號後五碼：{(info && info?.accountInfo) || '--'}
                        </span>
                        <span>
                          發票載具：{(info && info?.receiptBarcode) || '--'}
                        </span>
                        <span>
                          抬頭：{(info && info?.receiptTitle) || '--'}
                        </span>
                        <span>
                          統一編號：
                          {(info && info?.receiptUniformNumber) || '--'}
                        </span>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </Card>
          )}
          {!isCopy && (
            <Card
              className={style.search_card}
              style={{ marginBottom: '3rem' }}
            >
              <div className={style.card_header}>非經手人修改</div>
              <div className={style.card_body}>
                {info &&
                  info.notHandlerEdits.map((item, index) =>
                    index <= 2 ? (
                      <div key={index} className="mb-3">
                        <span style={{ color: '#3c44b1' }}>
                          {item.date} {item.admin}
                        </span>
                        {item.repairChanges.map((change, index) => (
                          <div className="mb-2" key={index}>
                            <label className="m-0">{change.title}</label>
                            <span>{change.content}</span>
                          </div>
                        ))}
                      </div>
                    ) : (
                      <></>
                    ),
                  )}
                <div className="d-flex justify-content-center mt-3">
                  <Button
                    type="submit"
                    className={style.modal_button_submit}
                    onClick={() => setAllShow(!allShow)}
                  >
                    <span className="btn-wrapper--label">全部內容</span>
                  </Button>
                </div>
              </div>
            </Card>
          )}
        </div>
      </div>
      {modal && maintainDetailsInfo && (
        <EditMaintainanceDetailModal
          isOpen={modal}
          toggle={toggleModal}
          isCopy={isCopy}
          setIsCopy={setIsCopy}
          dealingData={values}
          setValues={setValues}
        />
      )}
      <ModificationHandleModal
        info={info}
        allShow={allShow}
        toggleAllShow={toggleAllShow}
      />
      <MaintenanceProcessModal
        info={info}
        historyModal={historyModal}
        toggleHistoryModal={toggleHistoryModal}
      />
      {isExchangeWarrantyProductModalOpen && info && (
        <ExchangeWarrantyProductModal
          isOpen={isExchangeWarrantyProductModalOpen}
          toggle={toggleExchangeWarrantyProductModal}
          onRefresh={() => {
            dispatch(getMaintainDetails(id));
          }}
          info={{
            email: info.memberEmail,
            id: info.memberId,
            phone: info.memberPhone,
            repairRegisterNum: info.repairRegisterNum,
            supplier: maintainOptions.allChannels,

          }}
        />
      )}
      {isExchangeSerialNumberModalOpen && info && (
        <ExchangeSerialNumberModal
          isOpen={isExchangeSerialNumberModalOpen}
          toggle={toggleExchangeSerialNumberModal}
          onRefresh={() => {
            dispatch(getMaintainDetails(id));
          }}
          info={info}
        />
      )}
      {isEditStaffCodeModalOpen && info && (
        <EditStaffCodeModal
          isOpen={isEditStaffCodeModalOpen}
          toggle={toggleEditStaffCodeModal}
          onRefresh={() => {
            dispatch(getMaintainDetails(id));
          }}
          info={{
            employeeid: info.employeeid,
            id: id,
            records: info.employeeIdRecords,
            type: 2,
          }}
        />
      )}
    </Layout>
  );
};

export default MaintainanceContent;
